import React from 'react';
import { graphql } from 'gatsby'
import Layout from '../components/layout'
import SEO from '../components/seo'
import { Row, Col } from 'reactstrap'
import NewsPosts from '../components/news/news-post'
import Quotes from "../components/quotes"

export const query = graphql`
  query {
    wpgraphql {
        page(id: "cG9zdDoxNA==") {
          
      featuredImage {
        node {
          altText
          sourceUrl
          imageFile {
            childImageSharp {
              fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
    acf_page_meta {
      metadescription
      metatitle
    }
  }
      cpt_news {
        nodes {
          title
          excerpt
          featuredImage {
            node {
              altText
              sourceUrl
              imageFile {
                childImageSharp {
                  fluid {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
          acf_news {
            newsDate
            buttonUrl
            buttonText
        }
          }
      }
    }
  }
`
const News = ({ data, location }) => {

  return (
    
    
    <Layout navlocation={location.pathname}>
    <SEO
          title={data.wpgraphql.page.acf_page_meta.metatitle}
          description={data.wpgraphql.page.acf_page_meta.metadescription}
        />
            
            <Row className="d-flex home__card_news">
            <Col md={{ size:12 }} className="align-self-center text-center py-2 py-md-4">
            <h1 className="">AHDC in the <span className="text-primary" style={{ display: `inline`}}>News.</span></h1>
            
            
            
            </Col>
            </Row>
        
<NewsPosts/>
      
    <Quotes id="cG9zdDo0NjE=" />
    </Layout>
  )
}

export default News