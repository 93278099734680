import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import RevealNews from './reveal-news';

const NewsPosts = () => {
  const data = useStaticQuery(graphql`
    query NewsQuery {
      wpgraphql {
cpt_news {
  nodes {
    title
    excerpt
    featuredImage {
      node {
        altText
        sourceUrl
        imageFile {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
    acf_news {
      newsDate
      buttonText
      buttonUrl
    }
  }
}
}
}
      
  `)

  return (
    <article>
      {data.wpgraphql.cpt_news.nodes.map((items, i) => (
<RevealNews items={items}>
        
</RevealNews>
      ))}
    </article>
  );
};

export default NewsPosts;
