import React, { useState } from "react";
import { Link } from 'gatsby'
import { Waypoint } from "react-waypoint"
import { useSpring, animated, config } from "react-spring";
import { Row, Col } from "reactstrap";
import Img from "gatsby-image";

const RevealNews = (items) => {
  const [on, toggle] = useState(false);
  
  const revealamation = useSpring({
    from: { opacity: 0, transform: "scale(0.9)" },
    to: { opacity: on ? 1 : 0, transform: on ? "scale(1)" : "scale(0.9)" },
    config: config.gentle
  });
  const revealimage = useSpring({
    from: { opacity: 0, transform: "translate3d(0,50px,0)", },
    to: { opacity: on ? 1 : 0, transform: on ? "translate3d(0px, 0px, 0px)" : "translate3d(0,50px,0)", },
    config: config.slow,
    delay: 500
  });
  return (
    <Waypoint 
      bottomOffset='25%'
      onEnter={() => {
        if (!on) toggle(true)
      }}>
      <animated.div style={revealamation}>
        <Row className="bg-light mt-2 mt-md-3">
          <Col md={{ size: 8, offset: 2 }} lg={{ size: 11, offset: 1 }} className="pt-2 pt-md-5">
            <p className="text-primary"><span className="news__post-date">{items.items.acf_news.newsDate}</span></p>
          </Col>
        </Row>
        <Row className="bg-light news_item">
          <Col md={{ size: 8, offset: 2 }} lg={{ size: 4, offset: 1 }} className="pb-5">
          <h2 className="text-secondary pb-1">{items.items.title}</h2>
        <div dangerouslySetInnerHTML={{ __html: items.items.excerpt }}/>
          <Link className="btn btn-outline-secondary mt-2" to={items.items.acf_news.buttonUrl}>
          {items.items.acf_news.buttonText}
          </Link>      
        </Col>
        <Col md={{ size: 8, offset: 2 }} lg={{ size: 4, offset: 2 }} className="pb-2 pb-lg-5">
        {items.items.featuredImage != null && (
          <animated.div style={revealimage}>
            <Img
              className=""
              alt={ items.items.featuredImage.node.altText }
              fluid={items.items.featuredImage.node.imageFile.childImageSharp.fluid}
            />
          </animated.div>
        )}
        </Col>
        </Row>
      </animated.div>
    </Waypoint>
  )
}

export default RevealNews
